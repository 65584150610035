import React, { Component } from 'react';
import './MobileView.css'

class MobileView extends Component {

    flipButton(event)
    {
        let btn = event.currentTarget;
        let btn_children = btn.children[0];
        if(btn.classList.contains("flipped"))
        {
            btn.classList.remove("flipped");
            btn_children.classList.remove("flipped");
        }
        else
        {
            btn.classList.add("flipped");
            btn_children.classList.add("flipped");
        }

    }

    render() {
        return (
            <div className="row mobile-view">
                <div className="col-md-6 offset-md-3">
                    <div className="flip-btn" onClick={this.flipButton}>
                        <div className="flip-btn-inner" onClick={this.props.userClickMobileManager}>
                            <div className="flip-btn-front">
                                <button className="btn btn-mobile">
                                    <i className="fas fa-mobile-alt"></i>
                                </button>
                            </div>
                            <div className="flip-btn-back">
                                <button className="btn btn-mobile">
                                    <i className="fas fa-desktop"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}

export default MobileView

