import React, { Component } from 'react';

const TECHNO_ICON =
    {
        Bootstrap: "/img/tech/bootstrap.png",
        Wordpress: "/img/tech/wordpress.png",
        PHP: "/img/tech/php.png",
        Jquery: "/img/tech/jquery.png",
        Javascript: "/img/tech/js.png",
        Photoshop: "/img/tech/photoshop.png",
        React: "/img/tech/react.jpg",
        VueJS: "/img/tech/vuejs.png",
        TypeScript : "/img/tech/ts.png",
        Flask: "/img/tech/flask.png",
        Python: "/img/tech/python.png",
        Django: "/img/tech/django.png",
        GraphQL: "/img/tech/graphql.png",
        Docker: "/img/tech/docker.png",
    }


class Techno extends Component {

    render() {
        const {techno} = this.props
        return (
            <img src={TECHNO_ICON[techno]} className="techno-icon" alt={techno} title={techno} />
        )
    }


}

export default Techno
