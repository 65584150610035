import React, { Component } from 'react';
import "./OptionButton.css"

class OptionButton extends Component
{
    render() {
        return (
            <div>
                <div className="toggle-group switch-btn">
                    <input type="checkbox" name={"on-off-switch" + this.props.title}
                           id={"on-off-switch" + this.props.title}
                           tabIndex="1" onClick={this.props.optionFunction} />
                        <label htmlFor={"on-off-switch" + this.props.title}>
                            <span className="aural"></span> {this.props.options_texte}
                        </label>
                        <div className="onoffswitch pull-right" aria-hidden="true">
                            <div className="onoffswitch-label">
                                <div className="onoffswitch-inner"></div>
                                <div className="onoffswitch-switch"></div>
                            </div>
                        </div>
                </div>
            </div>

        )
    }
}


class OptionsButtonModal extends Component {
    render() {
        return (
            <div>
                <div className="btn-options-wrapper">
                    <button className="btn btn-options" data-toggle="modal" data-target="#exampleModalCenter">
                        <i className="fas fa-cog"></i>
                    </button>
                </div>
                <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">Options</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="modal-body">
                        <OptionButton
                            optionFunction={this.props.technoManager}
                            options_texte="Afficher les technologies"
                            title="technoManager"
                        />
                        <OptionButton
                            optionFunction={this.props.mobileViewManager}
                            options_texte="Voir les versions mobile"
                            title="mobileViewManager"
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn" data-dismiss="modal">Fermer</button>
                    </div>
                </div>
                </div>
                </div>
            </div>
        )
    }

    /*
    componentDidMount = () =>
    {
        console.log(this);
    }
    */
}

export default OptionsButtonModal