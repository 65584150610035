import React, { Component } from 'react';
import Typed from 'typed.js'
import './App.css';
import Portfolio from "./Portfolio";
import OptionsButtonModal from "./OptionButton"

import DATA from './data';

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstColumn: [],
            secondColumn: [],
            thirdColumn: [],
            showTechno: false,
            showMobile: false,
        }
        this.technoManager = this.technoManager.bind(this)
        this.mobileViewManager = this.mobileViewManager.bind(this)
        this.generateColumn()
    }

    technoManager(){
        let showTechno = this.state.showTechno
        showTechno = !showTechno
        this.setState({showTechno: showTechno})
    }
    mobileViewManager(){
        let showMobile = this.state.showMobile
        showMobile = !showMobile
        this.setState({showMobile: showMobile})
    }

    generateColumn() {
        var { firstColumn, secondColumn, thirdColumn } = this.state;
        var column = 1
        for(var i = 0; i < DATA.length; i++)
        {
            if(column === 1)
            {
                firstColumn.push(DATA[i])
            }
            else if (column === 2)
            {
                secondColumn.push(DATA[i])
            }
            else if(column === 3)
            {
                thirdColumn.push(DATA[i])
                column = 0 // va devenir un à la ligne suivant
            }
            column++;
        }


        this.setState({firstColumn: firstColumn,
            secondColumn: secondColumn,
            thirdColumn: thirdColumn
        });

    }

    componentDidMount() {
        setTimeout( () => {
            new Typed('#typed-text', {
                strings: ['Des sites <strong>modernes</strong>', 'Des sites <strong>responsives</strong>',
                    'Des sites <strong>professionels</strong>', 'Des sites <strong>variés</strong>'],
                typeSpeed: 32,
                backSpeed: 25,
                backDelay: 500,
                smartBackspace: true,
                loop: true
            });
        }, 700);
    }

    scrollDown()
    {
        document.querySelector('#portfolio-content').scrollIntoView({
            behavior: 'smooth'
        });
    }

    render() {
        const {firstColumn, secondColumn, thirdColumn, showTechno, showMobile} = this.state
        return (

            <div className="container-fluid">
                <div>
                    <div className="parallax">
                    </div>
                </div>
                <div className="row">
                    <div>
                        <OptionsButtonModal
                            technoManager={this.technoManager}
                            mobileViewManager={this.mobileViewManager}
                        />
                    </div>
                    <div className="col-md-10 offset-md-1 portfolio-wrapper">
                        <h1 className="text-center" id="main-title">Portfolio Nils VÆDE</h1>
                        <div className="type-wrapper text-center">
                            <span id="typed-text" className="text-center"></span>
                        </div>
                        <button onClick={this.scrollDown} className="btn btn-down">
                            <i className="fas fa-arrow-alt-circle-down"></i>
                        </button>
                        <div className="row" id="portfolio-content">
                            <div className="col-md-4">
                                {firstColumn.map((content, key) => (
                                    <Portfolio
                                        title={content.title}
                                        img={content.img}
                                        img_mobile={content.img_mobile}
                                        link={content.link}
                                        techno={content.techno}
                                        type_work={content.type_work}
                                        showTechno={showTechno}
                                        showMobile={showMobile}
                                        key={key}
                                    />
                                ))}
                            </div>
                            <div className="col-md-4">
                                {secondColumn.map((content, key) =>(
                                    <Portfolio
                                        title={content.title}
                                        img={content.img}
                                        img_mobile={content.img_mobile}
                                        link={content.link}
                                        techno={content.techno}
                                        type_work={content.type_work}
                                        showTechno={showTechno}
                                        showMobile={showMobile}
                                        key={key}
                                    />
                                ))}
                            </div>
                            <div className="col-md-4">
                                {thirdColumn.map((content, key) => (
                                    <Portfolio
                                        title={content.title}
                                        img={content.img}
                                        img_mobile={content.img_mobile}
                                        link={content.link}
                                        techno={content.techno}
                                        type_work={content.type_work}
                                        showTechno={showTechno}
                                        showMobile={showMobile}
                                        key={key}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="contact" className="row">
                    <div className="col-md-4 offset-md-4 col-10 offset-1">
                        <a href="mailto:nils@vaede.com" className="btn btn-contact">
                            Me contacter <i className="far fa-envelope"></i>
                        </a>
                    </div>
                </div>
                <footer className="text-center">
                    Made by <a href="http://nils.vaede.com">Nils VAEDE</a> with <i className="fab fa-react spin" title="react"></i>
                </footer>
            </div>
        );
    }
}

export default App;
